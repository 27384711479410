import type { ReactElement, ReactNode } from 'react';
import React, { useState, useEffect, createContext, useCallback } from 'react';
import { DEFAULT_MAP_ZOOM } from 'core/constants/constant';

interface SettingContextData {
  location: any;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  locationZoom: number;
  setLocationZoom: React.Dispatch<React.SetStateAction<any>>;
  showTopHeader: boolean;
  setShowTopHeader: React.Dispatch<React.SetStateAction<boolean>>;
  currentCity: string;
  setCurrentCity: React.Dispatch<React.SetStateAction<string>>;
  mProductShow: boolean;
  setMProductShow: React.Dispatch<React.SetStateAction<boolean>>;
  mProductShowImg: boolean;
  setMProductShowImg: React.Dispatch<React.SetStateAction<boolean>>;
  mProductShowDetail: any;
  setMPRoductShowDetail: React.Dispatch<React.SetStateAction<any>>;
  mProductIndex: number;
  setMProductIndex: React.Dispatch<React.SetStateAction<number>>;
  activeGradient: boolean;
  setActiveGradient: React.Dispatch<React.SetStateAction<boolean>>;
  disablecheckout: boolean;
  setDisableCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  quoteDialogIsOpen: boolean;
  setQuoteDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchDialogIsOpen: boolean;
  setSearchDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locationDialogIsOpen: boolean;
  setLocationDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  equipmentDialogIsOpen: boolean;
  setEquipmentDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loginDialogIsOpen: boolean;
  setLoginDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productCalendarDialogIsOpen: boolean;
  setProductCalendarDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeLocationUserInput: () => void,
  removeLocationUserLocation: () => void,
}

export const SettingContext = createContext<SettingContextData>({
  location: {},
  setLocation: () => {},
  locationZoom: DEFAULT_MAP_ZOOM,
  setLocationZoom: () => {},
  showTopHeader: true,
  setShowTopHeader: () => {},
  currentCity: '',
  setCurrentCity: () => {},
  mProductShow: false,
  setMProductShow: () => {},
  mProductShowImg: false,
  setMProductShowImg: () => {},
  mProductShowDetail: false,
  setMPRoductShowDetail: () => {},
  mProductIndex: -1,
  setMProductIndex: () => {},
  activeGradient : false,
  setActiveGradient : () => {},
  disablecheckout : false,
  setDisableCheckout : () => {},
  quoteDialogIsOpen: false,
  setQuoteDialogIsOpen: () => null,
  searchDialogIsOpen: false,
  setSearchDialogIsOpen: () => null,
  locationDialogIsOpen: false,
  setLocationDialogIsOpen: () => null,
  equipmentDialogIsOpen: false,
  setEquipmentDialogIsOpen: () => null,
  loginDialogIsOpen: false,
  setLoginDialogIsOpen: () => null,
  productCalendarDialogIsOpen: false,
  setProductCalendarDialogIsOpen: () => null,
  removeLocationUserInput: () => null,
  removeLocationUserLocation: () => null,
});

export interface ISettingProviderProperties {
  children: ReactNode;
}

function SettingProvider({ children }: ISettingProviderProperties):ReactElement {
  const [showTopHeader, setShowTopHeader] = useState(true);
  const [currentCity, setCurrentCity] = useState<string>('');
  const [locationZoom, setLocationZoom] = useState<number>(DEFAULT_MAP_ZOOM);
  const [mProductShow, setMProductShow] = useState(false);
  const [mProductShowImg, setMProductShowImg] = useState(false);
  const [mProductShowDetail, setMPRoductShowDetail] = useState({});
  const [mProductIndex, setMProductIndex] = useState(0);
  const [activeGradient, setActiveGradient] = useState(false);
  const [disablecheckout, setDisableCheckout] = useState(false);
  const [quoteDialogIsOpen, setQuoteDialogIsOpen] = useState(false);
  const [searchDialogIsOpen, setSearchDialogIsOpen] = useState(false);
  const [locationDialogIsOpen, setLocationDialogIsOpen] = useState(false);
  const [equipmentDialogIsOpen, setEquipmentDialogIsOpen] = useState(false);
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);
  const [productCalendarDialogIsOpen, setProductCalendarDialogIsOpen] = useState(false);

  const [location, setLocation] = useState(() => {
    const storedLocation = localStorage.getItem('Location');
    return storedLocation ? JSON.parse(storedLocation) : {};
  });

  const removeLocationUserInput = useCallback(() => {
    setLocation((loc: any) => ({ ...loc, userInput: false }));
  }, []);

  const removeLocationUserLocation = useCallback(() => {
    setLocation((loc: any) => ({ ...loc, userLocation: false }));
  }, []);

  useEffect(() => {
    localStorage.setItem('Location', JSON.stringify(location));
  }, [location]);

  const contextData: SettingContextData = {
    location,
    setLocation,
    locationZoom,
    setLocationZoom,
    showTopHeader,
    setShowTopHeader,
    currentCity,
    setCurrentCity,
    mProductShow,
    setMProductShow,
    mProductShowImg,
    setMProductShowImg,
    mProductShowDetail,
    setMPRoductShowDetail,
    mProductIndex,
    setMProductIndex,
    activeGradient,
    setActiveGradient,
    disablecheckout,
    setDisableCheckout,
    quoteDialogIsOpen,
    setQuoteDialogIsOpen,
    searchDialogIsOpen,
    setSearchDialogIsOpen,
    locationDialogIsOpen,
    setLocationDialogIsOpen,
    equipmentDialogIsOpen,
    setEquipmentDialogIsOpen,
    loginDialogIsOpen,
    setLoginDialogIsOpen,
    productCalendarDialogIsOpen,
    setProductCalendarDialogIsOpen,
    removeLocationUserInput,
    removeLocationUserLocation,
  };

  return (
    <SettingContext.Provider value={contextData}>
      {children}
    </SettingContext.Provider>
  );
}

export default SettingProvider;
