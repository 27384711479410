import { useContext, useEffect } from 'react';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import { SettingContext } from 'core/context/SettingContext';
import { URI_RESULTS} from 'core/constants/constant';
import { locationApi } from 'core/api/locations.api';

export const useUrlCity = () => {
  const { location, setLocation } = useContext(SettingContext);
  const [searchParams] = useSearchParams();

  const localCityName = location?.obj?.name;

  useEffect(() => {
    const queryString = Object.fromEntries([...searchParams]);
	  const cityName = queryString['city'] || '';
    if (!cityName) return;

    fetchCities(cityName).then((res) => {
      const city = res?.[0];
      if (!city) return;
	    setLocation(() => ({
	      label: city?.name,
	      obj: { lat: city?.lat, lng: city?.lng, name: city?.name },
	    }));
	    if (localCityName && localCityName !== cityName && !city) {
	      const url = new URL(window.location.origin+`${URI_RESULTS}?${qs.stringify({ ...queryString, city: localCityName })}`);
		    window.history.pushState(null, '', url.toString());
	    }
    });
  }, [searchParams]);

  const fetchCities = async (inputCity: string) => {
    try{
		const { data } = await locationApi.getCities(inputCity);

	    if (data && data.status === 'OK') {
	      return data?.cities;
	    }
	} catch (err) {
		console.log(err);
    }
    return {};
  };
}
