import { useContext, useEffect } from "react";
import { useClientIpInfo } from "./api";
import {
  clientLocationFetchedRecently,
  getClientLocationLat,
  getClientLocationLng,
  setClientLocationLat,
  setClientLocationLng,
  setClientLocationTime,
} from "utils";
import { SettingContext } from "core/context/SettingContext";
import { useSearchParams } from "react-router-dom";

export const useSetClientLocation = () => {
  const [searchParams] = useSearchParams();
  const { location, setLocation } = useContext(SettingContext);
  const { data: clientIpInfoData } = useClientIpInfo({
    staleTime: Infinity,
    enabled: !clientLocationFetchedRecently(),
  });

  const city = searchParams.get('city');

  useEffect(() => {
    if (clientLocationFetchedRecently()) { return; }

    const loc: string[] = clientIpInfoData
      ?.data?.loc?.split(',')?.map((latLng: string) => latLng.trim());

    if (loc?.length !== 2) { return; }

    setClientLocationLat(loc[0]);
    setClientLocationLng(loc[1]);
    setClientLocationTime();
  }, [clientIpInfoData]);

  useEffect(() => {
    if (city || location?.obj?.lat || location?.obj?.lng) { return }

    const lat = getClientLocationLat();
    const lng = getClientLocationLng();

    if (lat && lng) {
      setLocation(() => ({ obj: { lat, lng }, userLocation: true, userInput: false }))
    }
  }, [
    clientIpInfoData,
    city,
    location?.obj?.lat,
    location?.obj?.lng,
    setLocation
  ]);
};
