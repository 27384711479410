import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { commonApi } from 'core/api/common.api';
import { ClientIpInfoProps } from './types';

export const useClientIpInfo = ({
  staleTime = 1000 * 60 * 5,
  enabled = true,
}: ClientIpInfoProps = {}) => {
  return useQuery({
    queryKey: [queryKeys.clientIpInfo],
    queryFn: commonApi.getClientIPInfo,
    staleTime,
    enabled,
  });
}
