import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { commonApi } from 'core/api/common.api';
import { setAxiosAuth } from 'utils';
import { CustomerInfo } from './types';

export const useGetCustomerInfo = ({
  customerId,
  staleTime = 1000 * 60 * 5,
  accessToken = '',
}: CustomerInfo) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.customerInfo, customerId],
    queryFn: async () => await commonApi.getCustomerInfo(customerId),
    staleTime,
    enabled: Boolean(accessToken && customerId),
  });
}
