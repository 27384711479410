import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { commonApi } from 'core/api/common.api';
import { setAxiosAuth } from 'utils';
import { BookingInfo } from './types';

export const useGetBookingInfo = ({
  staleTime = 1000 * 60 * 5,
  accessToken = '',
  bookingId,
}: BookingInfo) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.bookingInfo, bookingId],
    queryFn: async () => await commonApi.getBookingInfo(bookingId),
    staleTime,
    enabled: Boolean(accessToken && bookingId),
  });
}
