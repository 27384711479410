export const queryKeys = {
  assetCalendar: 'assetCalendar',
  accessToken: 'accessToken',
  mapAssets: 'mapAssets',
  listAssets: 'listAssets',
  portalInfo: 'portalInfo',
  cities: 'cities',
  partnerInfo: 'partnerInfo',
  partnerAssets: 'partnerAssets',
  partnerLocations: 'partnerLocations',
  promotion: 'promotion',
  trackPortal: 'trackPortal',
  clientIpInfo: 'clietnIpInfo',
  bookingInfo: 'bookingInfo',
  customerInfo: 'customerInfo',
};
