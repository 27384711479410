import dayjs from 'dayjs';

export const CLIENT_LOCATION_SETTINGS_KEY = 'CLIENT_LOCATION_SETTINGS';
export const CLIENT_LOCATION_SETTINGS_KEYS = [
  'time',
  'lat',
  'lng',
] as const;

export const getClientLocationSettings = (key?: typeof CLIENT_LOCATION_SETTINGS_KEYS[number]) => {
  try {
    const settings = JSON.parse(localStorage.getItem(CLIENT_LOCATION_SETTINGS_KEY) || '{}');
    return key ? settings?.[key] : settings;
  } catch (err) {
    return {};
  }
}

export const setClientLocationSettings = (key: typeof CLIENT_LOCATION_SETTINGS_KEYS[number], value: string) => {
  const settings = getClientLocationSettings();
  settings[key] = value;
  localStorage.setItem(CLIENT_LOCATION_SETTINGS_KEY, JSON.stringify(settings));
}

export const removeClientLocationSettings = (key: typeof CLIENT_LOCATION_SETTINGS_KEYS[number]) => {
  const settings = getClientLocationSettings();
  delete settings[key];
  localStorage.setItem(CLIENT_LOCATION_SETTINGS_KEY, JSON.stringify(settings));
}

export const clientLocationFetchedRecently = () => {
  const timestamp = parseInt(`0${getClientLocationSettings('time')}`);
  const lastFetchDayJs = dayjs(new Date(timestamp));

  return Boolean(timestamp) && lastFetchDayJs.add(1, 'days').isAfter(dayjs());
}

export const setClientLocationTime = () => {
  setClientLocationSettings('time', new Date().getTime().toString());
}

export const setClientLocationLat = (lat: string) => {
  setClientLocationSettings('lat', lat);
}

export const getClientLocationLat = () => {
  return getClientLocationSettings('lat');
}

export const removeClientLocationLat = () => {
  removeClientLocationSettings('lat');
}

export const setClientLocationLng = (lng: string) => {
  setClientLocationSettings('lng', lng);
}

export const getClientLocationLng = () => {
  return getClientLocationSettings('lng');
}

export const removeClientLocationLng = () => {
  removeClientLocationSettings('lng');
}
